import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Parkinson(): ReactElement {
    return (
        <div>
            <div className="mx-auto px-5 lg:max-w-7xl relative w-full h-[320px]">
                <Image
                    src="/bestbabyformulalawyers/assets/images/powder_milk.jpg"
                    alt="baby"
                    layout="fill"
                    objectFit="cover"
                    className="z-0"
                />
            </div>
            <div className={`mx-auto px-5 lg:max-w-7xl  ${style.wrapper}`}>
                <h2>What Is Necrotizing Enterocolitis (NEC)?</h2>
                <p>
                    Necrotizing Enterocolitis, often abbreviated as NEC, is a
                    gastrointestinal condition characterized by the death of
                    intestinal tissue or the development of intestinal
                    perforations that allow bacteria to infiltrate, leading to
                    infections. This condition predominantly affects premature
                    newborn babies.
                </p>
                <p className="mt-10">
                    Many formula brands are fortified with cow's milk, which
                    contains enzymes and bacteria that may not be suitable for
                    premature newborns. Due to their underdeveloped and fragile
                    digestive systems, newborns are more susceptible to
                    intestinal infections that their immune systems struggle to
                    combat effectively. Additionally, the intestines may face
                    challenges in receiving adequately oxygenated blood,
                    rendering the digestive tract more vulnerable to tissue
                    damage and infection.
                </p>
            </div>
        </div>
    );
}
